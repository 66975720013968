<template>
  <el-dialog
    append-to-body
    width="800px"
    title="评价详情"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">评价详情</div>
    </template>

    <div class="content">
      <div class="score mb5">{{info.score}}</div>
      <div class="flex justify-between mb30">
        <el-rate
          v-model="info.score"
          disabled
          disabled-void-icon-class="icon-rate-face-off"
          text-color="#ff9900">
        </el-rate>
        <span>{{info.create_time}}</span>
      </div>
      <el-divider></el-divider>
      <el-row class="mb30">
        <el-col :span="12">
          <span class="label">评价人：</span>
          <span>{{info.teacher_name}}</span>
        </el-col>
        <el-col :span="12">
          <span class="label">所属学校：</span>
          <span>{{info.school_name}}</span>
        </el-col>
      </el-row>
      <el-row class="mb30">
        <el-col :span="12">
          <span class="label mb10">研学主题：</span>
          <span>{{info.title}}</span>
        </el-col>
        <el-col :span="12">
          <span class="label mb10">产品名称：</span>
          <span>{{info.good_title}}</span>
        </el-col>
      </el-row>
      <el-row class="mb30">
        <el-col :span="24">
          <div class="label mb10">产品描述</div>
          <div>{{info.good_info.description}}</div>
        </el-col>
      </el-row>
      <el-row class="mb10">
        <el-col :span="24">
          <div class="label mb10">评价内容：</div>
          <div>{{info.content}}</div>
        </el-col>
      </el-row>
      <div>
        <el-image
          v-for="item in info.images"
          :key="item"
          class="img"
          :src="item"
          fit="cover"
          :preview-src-list="info.images"
          ></el-image>
      </div>
      
    </div>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'MienEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        info: {
          score: '',
          create_time: '',
          teacher_name: '',
          school_name: '',
          content: '',
          images: [],
          good_info:{}
        }
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.info, row)
        this.info.score = Number(row.score)
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>

<style scoped>
.score {
  font-size: 24px;
  font-weight: bold;
  color: #F53030;
}
.content {
  font-size: 16px;
  line-height: 20px;
  padding: 0 18px;
  overflow-y: scroll;
  max-height: 600px;
}
.img {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  margin: 10px 20px 10px 0;
}
.label {
  color: #999999;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.content ::v-deep .el-rate__icon {
  font-size: 30px !important;
}
</style>
